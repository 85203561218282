import COC from './CardinalOfCarnage.png'
import cd from './cd.jpeg'
import poster from './poster.jpeg'
import sticker from './sticker.jpeg'
import tshirt from './tshirt.jpeg'

import * as PropTypes from "prop-types";

function Page({children}) {
  return <div className={'page col bg-black text-white'}>
      {children}
  </div>;
}

Page.propTypes = {children: PropTypes.node};

function Landing({children}) {
  return <div className={'landing h-screen flex flex-col'}>
      {children}
  </div>;
}

Landing.propTypes = {children: PropTypes.node};

function Header({children}) {
  return <h1 className={'header uppercase font-roboto text-4xl font-bold text-center pt-4 lg:text-6xl'}>
      {children}
  </h1>;
}

Header.propTypes = {children: PropTypes.node};

function Body({children}) {
  return <div className={'body grow flex justify-center'}>
      {children}
  </div>;
}

Body.propTypes = {children: PropTypes.node};

function Footer({children}) {
  return <div className={'footer pb-4'}>
    {children}
  </div>;
}

Footer.propTypes = {children: PropTypes.node};

function Menu({children}) {
  return <div className={'menu flex flex-row gap-5 justify-center mt-2 mb-4 pb-6'}>
    {children}
  </div>;
}

function Section({children, id}) {
  return <div id={id} className={'section flex flex-col'}>
    {children}
  </div>;
}

Section.propTypes = {children: PropTypes.node};

function Title({children}) {
  return <h2 className={'title text-3xl uppercase font-roboto py-5 text-center mt-6'}>
    {children}
  </h2>;
}

Title.propTypes = {children: PropTypes.node};

function MenuItem({children, href}) {
  return <a href={href} className={'menu-item uppercase font-roboto text-2xl lg:text-4xl hover:underline hover:cursor-pointer'}>
    {children}
  </a>;
}

MenuItem.propTypes = {children: PropTypes.node};

function Logo() {
  return <img src={COC} className={"object-scale-down min-h-full w-10/12 lg:h-full lg:w-1/5"} alt={"Whack Cardinal of Carnage logo"}/>;
}

function SectionBody({children}) {
  return <div className={"section-body"}>{children}</div>;
}

function UpcomingShows({children}) {
  return <div className={"upcoming-shows"}>{children}</div>;
}

UpcomingShows.propTypes = {children: PropTypes.node};

function Show({date, city, venue, state}) {
  return <div className={"grid grid-rows-1 grid-cols-2 justify-center align-middle my-5 gap-5 font-roboto even:text-cyan-400 odd:text-pink-500"}>
    <span className={"justify-self-center align-middle h-1/2 my-auto text-white lg:justify-self-end"}>
      {date}
    </span>
    <div className={"justify-self-start"}>
      <div className={"text-white"}>{city}, {state}</div>
      <div className={"uppercase font-medium text-xs italic"}>{venue}</div>
    </div>
  </div>;
}

Show.propTypes = {
  date: PropTypes.string,
  venue: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string
};

function MerchSection({children}) {
  return <div className={"merch-section flex flex-row flex-wrap justify-center align-middle gap-12 mx-5"}>
    {children}
  </div>
}

MerchSection.propTypes = {children: PropTypes.node};

function Merch({imageSrc, href, title, price}) {
  return <a className={"merch-item w-1/2 justify-self-center align-middle text-center group w-[600px]"} href={href}>
    <div className={"mt-2 mb-2 text-2xl uppercase font-roboto"}>
      {title}
    </div>
    <div>
      <img src={imageSrc} title={title} className={"rounded-3xl object-cover " +
          "shadow-md shadow-white " +
          "group-hover:shadow-lg " +
          "group-odd:group-hover:shadow-cyan-400 " +
          "group-even:group-hover:shadow-pink-400"}/>
    </div>
    <div className={"mt-2 font-roboto font-bold"}>
      {price}
    </div>
  </a>;
}

Merch.propTypes = {
  price: PropTypes.string,
  imageSrc: PropTypes.any,
  href: PropTypes.string,
  title: PropTypes.string
};

function App() {
  return (
      <Page>
        <Landing>
          <Header>
            Whack Productions
          </Header>
          <Body>
            <Logo/>
          </Body>
          <Footer>
            <Menu>
              <MenuItem href={"#shows"}>Upcoming Shows</MenuItem>
              <MenuItem href={"#merch"}>Merch</MenuItem>
            </Menu>
          </Footer>
        </Landing>
        <Section id={"shows"}>
          <Title>
            Upcoming Shows
          </Title>
          <SectionBody>
            <UpcomingShows>
              {/*<Show date={'8/24/2024'} city={'Youngstown'} state={'OH'} venue={'Westside Bowl'}/>*/}
              {/*<Show date={'8/25/2024'} city={'Millersville'} state={'PA'} venue={'Phantom Power'}/>*/}
              <Show date={'8/26/2024'} city={'Virginia Beach'} state={'VA'} venue={'Scandals Live'}/>
              <Show date={'8/28/2024'} city={'Rochester'} state={'NY'} venue={'Montage Music Hall'}/>
              <Show date={'8/29/2024'} city={'Albany'} state={'NY'} venue={'Empire Underground'}/>
              <Show date={'8/30/2024'} city={'Manchester'} state={'NH'} venue={'Leathal Weekend 7'}/>
            </UpcomingShows>
          </SectionBody>
        </Section>
        <Section id={"merch"}>
          <Title>
            Merch
          </Title>
          <SectionBody>
            <MerchSection>
              <Merch imageSrc={tshirt} title={"T-Shirt"} price={"$25"} href={"https://checkout.square.site/merchant/MLHTGHZZECXJN/checkout/IDTYX7UWQOICIMRJ7H6IVXYN"}/>
              <Merch imageSrc={cd} title={"CD"} price={"$10"} href={"https://checkout.square.site/merchant/MLHTGHZZECXJN/checkout/EPMWVW5QUAZDTJ4LWHIALT7G"}/>
              <Merch imageSrc={sticker} title={"Sticker"} price={"$3"} href={"https://checkout.square.site/merchant/MLHTGHZZECXJN/checkout/2SO5JPPDBRTCWUQ5AIYLLUBT"}/>
              <Merch imageSrc={poster} title={"Poster"} price={"$15"} href={"https://checkout.square.site/merchant/MLHTGHZZECXJN/checkout/M5WCRDALZZLZLOROIULYI26H"}/>
            </MerchSection>
          </SectionBody>
        </Section>
      </Page>

  );
}

export default App;
